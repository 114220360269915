<template>
  <div v-if="userLoggedIn" class="container-fluid">
    <section id="plate" v-if="this.data.status=='default'">
      <div class="row d-flex justify-content-center">
        <div class="col-11 d-flex justify-content-between upper_row">
          <p id="reg_month">{{this.data.registration_month}}</p>
          <p id="reg_year">{{this.data.registration_year}}</p>
        </div>
        <div class="col-11">
          <div class="middle_row">
            <div class="row">
              <div class="col-3">
                <p class="year" id="year">
                  {{this.data.additional_registration_number}}
                </p>
              </div>
              <div class="col-3 flex-column">
                <p class="arabic_text">الإمارات<br>
                  أبو ظبي</p>
                <p class="letters">U.A.E A.D</p>
              </div>
              <div class="col-6">
                <p id="plate_number">{{this.data.plate_number}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 lower_row">
          <p id="custom_message">
            {{this.data.message}}
          </p>
        </div>
      </div>
    </section>

    <section id="plate" class="stolen_plate"  v-if="this.data.status=='stolen'">
      <div class="row d-flex justify-content-center">
        <div class="col-11 upper_row">
          <p id="stolen_alert">---------STOLEN--------------</p>
        </div>
        <div class="col-11">
          <div class="middle_row">
            <div class="row">
              <div class="col-3">
                <p class="year" id="year">
                  {{this.data.additional_registration_number}}
                </p>
              </div>
              <div class="col-3 flex-column">
                <p class="arabic_text">الإمارات<br>
                  أبو ظبي</p>
                <p class="letters">U.A.E A.D</p>
              </div>
              <div class="col-6">
                <p id="plate_number">{{this.data.plate_number}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 lower_row">
          <p id="custom_message">
            {{this.data.message}}
          </p>
        </div>
      </div>
    </section>

    <section id="plate" class="alert_plate" v-if="this.data.status=='alert'">
      <div class="row d-flex justify-content-center">
        <div class="col-4 p-0 upper_row">
          <p id="general_alert">Alert</p>
        </div>
        <div class="col-7 p-0">
          <div class="middle_row">
            <div class="row">
              <div class="col-3">
                <p class="year" id="year">
                  {{this.data.additional_registration_number}}
                </p>
              </div>
              <div class="col-3 flex-column">
                <p class="arabic_text">الإمارات<br>
                  أبو ظبي</p>
                <p class="letters">U.A.E A.D</p>
              </div>
              <div class="col-6">
                <p id="plate_number">{{this.data.plate_number}}</p>
              </div>
            </div>
          </div>
        </div>
        <div class="col-11 lower_row">
          <p id="custom_message">
            {{this.data.message}}
          </p>
        </div>
      </div>
    </section>

  </div>
</template>

<script>
import './assets/styles/bootstrap.min.css';
import './assets/styles/style.css';
import {getMessaging, getToken, onMessage} from "firebase/messaging";
import axios from "axios";
import {firestorePlugin} from "vuefire";

export default {
  name: 'App',
  plugins: [firestorePlugin],
  data() {
    return {
      userLoggedIn : false,
      data: {},
      form: {
        email:"",
        password:"",
        plate_number:"",
        deviceToken:""
      },
    }
  },
  mounted(){
    const messaging = getMessaging();
    onMessage(messaging, (payload) => {
      // console.log('Message received Fore Ground. ', payload.data.body);
      this.data = JSON.parse(payload.data.plate);
      localStorage.setItem('plate_data', JSON.stringify(this.data));
    });

    const plateData = localStorage.getItem('plate_data');
    if (plateData) {
      this.data = JSON.parse(plateData);
      this.userLoggedIn = true;
    }else{
      this.login();
    }
  },
  methods: {
    async login() {
      console.log('Requesting permission...');
      const messaging = getMessaging();
      Notification.requestPermission().then(async (permission) => {
        if (permission === 'granted') {
          console.log('Notification permission granted.');
          await getToken(messaging).then((token) => {
            localStorage.setItem("deviceToken", token);
            this.deviceToken = token;
            this.doLogin();
            //send the token to your server
          }).catch((err) => {
            alert('An error occurred while retrieving token. '+ err);
          });
        }else{
          alert("Please Allow Notifications In order to Login.");
        }
      })
    },
    async doLogin(){
      // let body = {
      //   email : this.form.email,
      //   password : this.form.password,
      //   plate_number : this.form.plate_number,
      //   device_id : this.deviceToken,
      // }
      let body = localStorage.getItem('user_credentials');
      const config = {
        headers: {
          Authorization: process.env.VUE_APP_API_TOKEN,
          'Content-Type': 'application/json'
        }
      };
      axios.post(process.env.VUE_APP_API_URL + process.env.VUE_APP_LOGIN_URL,body,config)
          .then((response)=>{
            if(response.data.status == 200){
              console.log(response.data);
              this.error = null;
              this.user = response.data.data.plate;
              if (localStorage.getItem('plate_data') !== null) {
                localStorage.removeItem('plate_data');
              }
              localStorage.setItem('plate_data', JSON.stringify(this.user));
              // localStorage.setItem('user_credentials', JSON.stringify(body));
              this.data = this.user
              this.userLoggedIn = true;
            }else{
              return this.error = response.message;
            }
          })
          .catch((error)=>{
            this.error = 'No record found please try again.';
            console.log(error)
          })
    },
  },
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
