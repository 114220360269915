import { createApp } from 'vue'
import App from './App.vue'
// Importing the functions I need from the SDKs you need
import { initializeApp } from "firebase/app";

// My web app's Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyCl5GHXMPXEJCOKL3Dcz7bGpFypUKPeSqQ",
    authDomain: "digital-plate-5f9eb.firebaseapp.com",
    projectId: "digital-plate-5f9eb",
    storageBucket: "digital-plate-5f9eb.appspot.com",
    messagingSenderId: "1042780406919",
    appId: "1:1042780406919:web:0353306556faba355ca422"
};

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.register('/firebase-messaging-sw.js')
        .then(registration => {
            console.log('Firebase messaging service worker registered: ', registration);
        }).catch(err => {
        console.log('Firebase messaging service worker registration failed: ',err);
    });
}

// Initializing Firebase
initializeApp(firebaseConfig);

createApp(App).mount('#app')
